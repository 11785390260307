// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

require('prismjs/themes/prism.css');
require('katex/dist/katex.min.css');

/* eslint-disable-next-line import/prefer-default-export */
export function onServiceWorkerUpdateReady() {
  window.location.reload(true);
}
